import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Div100Vh from "react-div-100vh"
import SEO from "../components/seo"
import Layout from "../components/layout"
import Circle from "../components/circle"

const IndexPage = () => (
  <Layout header={false}>
    <SEO title="Home" />
    <Div100Vh className="index">
      <div className="index-container">
        <div className="index-circles">
          <Circle name="peter.digital" link="peter.digital">
            <StaticImage
              alt="Peter Digital Logo"
              src="../images/pd-circle.png"
              className="circle-image"
              placeholder="none"
              tracedSVGOptions={{ color: "#000000" }}
              height={300}
              width={300}
              loading="eager"
              formats={["webp", "auto"]}
              draggable={false}
            />
          </Circle>
          {/* <Circle name="carboncopy" link="carboncopy.online">
            <StaticImage
              alt="Carbon Copy Logo"
              src="../images/cc-circle.png"
              className="circle-image"
              placeholder="none"
              tracedSVGOptions={{ color: "#000000" }}
              height={300}
              width={300}
              loading="eager"
              formats={["webp", "auto"]}
              draggable={false}
            />
          </Circle> */}
          <Circle name="The SOAR Initiative" link="thesoarinitiative.org">
            <StaticImage
              alt="The SOAR Initiative Logo"
              src="../images/soar.png"
              className="circle-image"
              placeholder="none"
              tracedSVGOptions={{ color: "#000000" }}
              height={300}
              width={300}
              loading="eager"
              formats={["webp", "auto"]}
              draggable={false}
            />
          </Circle>
        </div>
      </div>

      <div className="index-links">
        <div className="index-links-container">
          <Link className="index-links-link" to="spam">
            Anti-spam policy
          </Link>
        </div>
      </div>
    </Div100Vh>
  </Layout>
)

export default IndexPage
