import React from "react"

import PropTypes from "prop-types"
import { OutboundLink } from "gatsby-plugin-google-gtag"

const Circle = ({ name, link, children }) => (
  <div className="circle">
    <OutboundLink href={`https://${link}`}>{children}</OutboundLink>
    <h3 className="circle-link">
      <OutboundLink href={`https://${link}`}>{name}</OutboundLink>
    </h3>
  </div>
)

Circle.propTypes = {
  name: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default Circle
